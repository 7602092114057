import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import FooterNavigation from './FooterNavigation'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <footer className="footer">
      <div className="container">
        <FooterNavigation />
        <div className="social-area pull-right">
          <a href="https://line.me/ti/p/Mh4ko8Cw1T">
            <img
              src="https://d.line-scdn.net/r/web/social-plugin/img/common/square-default-large.png"
              width="18"
              height="18"
              title="Share this page on LINE."
            ></img>
          </a>
          <a href="mailto:sales-aw@awsecuresoft.com">
            <i className="fa fa-envelope"></i>
          </a>
          <a href="tel:+6684-3155008" alt="084-315-5008">
            <i className="fa fa-phone-square" aria-hidden="true"></i>
          </a>
          <a href="tel:+6684-0159775" alt="084-015-9775">
            <i className="fa fa-mobile" aria-hidden="true"></i>
          </a>
        </div>
        <div className="copyright">
          &copy; 2021 <Link to="/">{data.site.siteMetadata.title}</Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
