import React from 'react'
import { useStaticQuery, graphql, Link, withPrefix } from 'gatsby'

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <header>
      <nav className="navbar navbar-transparent navbar-top" role="navigation">
        <div className="container">
          <div className="navbar-header">
            <button
              id="menu-toggle"
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target="#toggle-menu"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar bar1"></span>
              <span className="icon-bar bar2"></span>
              <span className="icon-bar bar3"></span>
            </button>
            <Link to="/">
              <div className="logo-container">
                <div className="logo">
                  <img
                    src={withPrefix('../assets/img/logo_AW.png')}
                    alt="AW secure soft Logo"
                  />
                </div>
                <div className="brand aw-brand">
                  {data.site.siteMetadata.title}
                </div>
              </div>
            </Link>
          </div>
          <div className="collapse navbar-collapse" id="toggle-menu">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/products">Products & Services</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
