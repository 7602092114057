import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import AOS from 'aos'
import Animate from 'animate.css'

import Head from './head'
import Header from './header'
import Footer from './footer'

const Layout = ({ title, children }) => {
  useEffect(() => {
    const aos = AOS
    aos.init({
      duration: 1100,
    })
    return () => {
      aos.refresh()
    }
  })
  return (
    <>
      <Head title={title} />
      <div className="landing-page landing-page1">
        <div className="wrapper" data-aos="fade-zoom-down">
          <Header />
          {children}
          <Footer />
        </div>
      </div>
      <Helmet>
        <script
          src={withPrefix('../assets/js/jquery-1.10.2.js')}
          type="text/javascript"
        ></script>
        <script
          src={withPrefix('../assets/js/jquery-ui-1.10.4.custom.min.js')}
          type="text/javascript"
        ></script>
        <script
          src={withPrefix('../assets/js/bootstrap.js')}
          type="text/javascript"
        ></script>
        <script
          src={withPrefix('../assets/js/awesome-landing-page.js')}
          type="text/javascript"
        ></script>
      </Helmet>
    </>
  )
}

export default Layout
