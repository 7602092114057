import React from 'react'
import { Link } from 'gatsby'

const FooterNavigation = () => {
  return (
    <nav className="pull-left">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/products">Products & Services</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  )
}

export default FooterNavigation
