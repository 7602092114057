import React from 'react'
import { withPrefix } from 'gatsby'

const Parallax = ({
  background,
  icon,
  head,
  paragraph,
  email,
  button,
  componentStyle,
  img,
  contact,
}) => {
  return (
    <div
      className={`parallax filter-gradient blue ${componentStyle}`}
      data-color="blue"
    >
      <div className="parallax-background">
        <img
          loading="lazy"
          className="parallax-background-image"
          src={withPrefix(`assets/img/bg/${background}`)}
          alt="background"
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            {icon ? (
              <div className="parallax-image">
                <img
                  loading="lazy"
                  className="img icon"
                  src={withPrefix(`assets/img/${icon}`)}
                  alt="laptop"
                />
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-md-offset-1">
            <div className={`description ${contact}`}>
              <h2 className="text-center">{head}</h2>
              <br />
              {paragraph ? (
                <h5 className="description--title">{paragraph}</h5>
              ) : undefined}
              {img ? (
                <a href="https://line.me/ti/p/Mh4ko8Cw1T">
                  <img
                    src={withPrefix(`../assets/img/${img}`)}
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  />
                </a>
              ) : undefined}
              {email ? (
                <div>
                  <a href="tel:+6684-3155008">
                    <h4 className="text-center">
                      <i className="fa fa-phone-square" aria-hidden="true"></i>{' '}
                      084-315-5008
                    </h4>
                  </a>
                  <a href="tel:+6684-0159775">
                    <h4 className="text-center">
                      <i className="fa fa-phone-square" aria-hidden="true"></i>{' '}
                      084-015-9775
                    </h4>
                  </a>
                  <a href={`mailto:${email}`}>
                    <h4 className="text-center">
                      <i className="fa fa-envelope"></i> {email}
                    </h4>
                  </a>
                </div>
              ) : undefined}
            </div>
            {button ? (
              <div className="buttons">
                <button className="btn btn-simple btn-neutral">
                  <i className="fa fa-apple"></i>
                </button>
                <button className="btn btn-simple btn-neutral">
                  <i className="fa fa-android"></i>
                </button>
                <button className="btn btn-simple btn-neutral">
                  <i className="fa fa-windows"></i>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Parallax
